import domReady from '@wordpress/dom-ready';
import apiFetch from '@wordpress/api-fetch';
import {
	createRoot,
	useState,
	useRef,
	useEffect,
	useCallback,
} from '@wordpress/element';
import {
	GoogleMap,
	LoadScript,
	Marker,
	InfoWindow,
} from '@react-google-maps/api';
import { Spinner } from '@wordpress/components';

const svg =
	'<svg width="28" height="40" viewBox="0 0 143 203" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.8" clip-path="url(#clip0_1_2)"><path d="M143 71.5C143 115.5 104 159.5 71.5 203C39.5 158.5 0 110.988 0 71.5C0 32.0116 32.0116 0 71.5 0C110.988 0 143 32.0116 143 71.5Z" fill="#B90E59"/><path d="M112 71.5C112 93.8675 93.8675 112 71.5 112C49.1325 112 31 93.8675 31 71.5C31 49.1325 49.1325 31 71.5 31C93.8675 31 112 49.1325 112 71.5Z" fill="white"/><path d="M98 71.5C98 86.1355 86.1355 98 71.5 98C56.8645 98 45 86.1355 45 71.5C45 56.8645 56.8645 45 71.5 45C86.1355 45 98 56.8645 98 71.5Z" fill="#B90E59"/></g><defs><clipPath id="clip0_1_2"><rect width="143" height="203" fill="white"/></clipPath></defs></svg>';

const containerStyle = {
	width: '100%',
	height: '720px',
};

const center = {
	lat: parseFloat( 46.800663464 ),
	lng: parseFloat( 8.222665776 ),
};

function TheMap( props ) {
	const { apiKey, mapZoom, markers, restApi } = props;

	const [ infoBoxStates, setInfoBoxStates ] = useState(
		markers.map( () => false )
	);

	const [ media, setMedia ] = useState( null );
	const [ theImage, setTheImage ] = useState( null );

	const onLoad = useCallback( function callback( map ) {
		const bounds = new window.google.maps.LatLngBounds();
		markers.forEach( ( marker ) => {
			const markerPosition = new google.maps.LatLng(
				parseFloat( marker.latitude ),
				parseFloat( marker.longitude )
			);
			const theMarker = new google.maps.Marker( {
				position: markerPosition,
				map: map,
			} );

			bounds.extend( theMarker.getPosition() );
		} );

		map.fitBounds( bounds ); //auto-zoom
		const zoomChangeBoundsListener = new google.maps.event.addListener(
			map,
			'bounds_changed',
			function ( event ) {
				google.maps.event.removeListener( zoomChangeBoundsListener );
				map.setZoom( Math.min( parseInt( mapZoom ), map.getZoom() ) );
			}
		);
		// new google.maps.event.addListenerOnce(
		// 	map,
		// 	'bounds_changed',
		// 	function () {
		// 		this.setZoom( map.getZoom() - 1 );
		// 		if ( this.getZoom() > 15 ) {
		// 			this.setZoom( 15 );
		// 		}
		// 	}
		// );
	}, [] );

	useEffect( () => {
		setTheImage( null );

		if ( media ) {
			apiFetch( { path: `wp/v2/media/${ media }` } ).then( ( data ) => {
				setTheImage( data );
			} );
		}

		return () => {
			setMedia( null );
		};
	}, [ media ] );

	return (
		<LoadScript googleMapsApiKey={ apiKey }>
			<GoogleMap
				mapContainerStyle={ containerStyle }
				center={ center }
				onClick={ () => {
					const updatedStates = [ ...infoBoxStates ];
					updatedStates.forEach( ( state, index ) => {
						updatedStates[ index ] = false;
					} );
					setInfoBoxStates( updatedStates );
				} }
				zoom={ mapZoom }
				mapTypeId={ 'roadmap' }
				onLoad={ onLoad }
			>
				{ markers.map( ( marker, index ) => {
					const {
						email,
						hotline,
						latitude,
						longitude,
						media_id,
						phone,
						street,
						title,
						town,
						zip,
					} = marker;

					const position = {
						lat: parseFloat( latitude ),
						lng: parseFloat( longitude ),
					};
					return (
						<Marker
							key={ index }
							icon={ `image/svg+xml;charset=utf-8,${ encodeURIComponent(
								svg
							) }` }
							title={ title }
							position={ position }
							onClick={ () => {
								setMedia( media_id );
								const updatedStates = [ ...infoBoxStates ];
								updatedStates[ index ] = true;
								setInfoBoxStates( updatedStates );
							} }
						>
							{ infoBoxStates[ index ] && (
								<InfoWindow
									options={ {
										pixelOffset:
											new window.google.maps.Size(
												0,
												-15
											),
										maxWidth: 300,
										minWidth: 300,
									} }
									position={ position }
									onCloseClick={ () => {
										const updatedStates = [
											...infoBoxStates,
										];
										updatedStates[ index ] = false;
										setInfoBoxStates( updatedStates );
									} }
								>
									<div className={ 'the-map-info-window' }>
										{ media_id && (
											<>
												{ /*{ ! theImage && <Spinner /> }*/ }
												{ ! theImage && (
													<div
														className={
															'empty-image'
														}
													>
														<Spinner />
													</div>
												) }
												{ theImage && (
													<div
														className={
															'the-map-info-window-image'
														}
													>
														<img
															src={
																theImage?.source_url
															}
															width={
																theImage?.width
															}
															height={
																theImage?.height
															}
															alt={
																theImage?.alt
															}
														/>
													</div>
												) }
											</>
										) }
										<div
											className={
												'the-map-info-window-content'
											}
										>
											{ title && <h3>{ title }</h3> }
											<ul>
												{ street && (
													<li>{ street }</li>
												) }
												{ zip && town && (
													<li>
														{ zip ? zip : '' }
														<span>
															{ town ? town : '' }
														</span>
													</li>
												) }
												{ phone && (
													<li>Telefon: { phone }</li>
												) }
												{ hotline && (
													<li>
														Hotline: { hotline }
													</li>
												) }
												{ email && (
													<li>Mail: { email }</li>
												) }
											</ul>
										</div>
									</div>
								</InfoWindow>
							) }
						</Marker>
					);
				} ) }
			</GoogleMap>
		</LoadScript>
	);
}

domReady( function () {
	const apiKey = window.l18n_js_map_vars.apiKey;
	const pin = window.l18n_js_map_vars.pin;
	const mediaRestApi = window.l18n_js_map_vars.media_rest_api;
	const mapWrapper = document.querySelectorAll( '[rel="theMap"]' );

	mapWrapper.forEach( ( map, index ) => {
		const locations = JSON.parse( map.dataset.locations );
		const mapZoom = JSON.parse( map.dataset.mapZoom );
		createRoot( map ).render(
			<TheMap
				apiKey={ apiKey }
				mapZoom={ mapZoom }
				markers={ locations }
				restApi={ mediaRestApi }
			/>
		);
	} );
} );
